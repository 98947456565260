<template>
  <div class="row">
    <div class="col-12">
      <!-- <div
        class="salert alert alert-custom alert-white alert-shadow fade show gutter-b alert-light"
      >
        <div class="alert-text">
          <div>
            <p>search</p>
          </div>
        </div>
      </div>-->

      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">Qo'shimcha shartnoma registratsiyasi</h3>
          </div>
        </div>

        <div class="card-body">
          <div class="d-flex">
            <div class="col-4 pl-0">
              <v-text-field
                disabled
                label="Firma nomi"
                :value="getClientContract.client_name"
                dense
                outlined
              ></v-text-field>
            </div>

            <div class="col-4">
              <div v-if="getClientContract.client_inn == null">
                <v-text-field
                  disabled
                  label="INN"
                  placeholder="Inn topilmadi"
                  dense
                  outlined
                ></v-text-field>
              </div>
              <div v-else>
                <v-text-field
                  disabled
                  label="INN"
                  :value="getClientContract.client_inn"
                  dense
                  outlined
                ></v-text-field>
              </div>
            </div>

            <div class="col-4 pr-0">
              <!-- <label>Shartnoma turi</label>
            <select
              v-model="typeOfContract"
              @change="changed"
              class="form-control form-control-solid form-control-lg"
            >
              <option value="MU">Muddatli</option>
              <option value="MS">Muddati cheklanmagan</option>
              <option value="SU">Summaga bog'liq</option>
            </select> -->
              <v-select
                @change="changed"
                v-model="typeOfContract"
                item-text="state"
                dense
                outlined
                item-value="abbr"
                :items="items"
                label="Shartnoma turi"
              ></v-select>
            </div>
          </div>
          <div class="d-flex">
            <div class="col-4 pl-0">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateOFContract"
                    label="Shartnoma sanasi"
                    append-icon="event"
                    dense
                    outlined
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateOFContract"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </div>
            <div class="col-4 pr-0" v-if="typeOfContract == 'MU'">
              <v-menu
                v-model="menu3"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="endOfContract"
                    label="Shartnoma tugash sanasi"
                    append-icon="event"
                    readonly
                    dense
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="endOfContract"
                  @input="menu3 = false"
                ></v-date-picker>
              </v-menu>
            </div>
            <div class="col-4 pl-0" v-if="typeOfContract == 'SU'">
              <v-text-field
                label="Shartnoma summasi"
                v-model="amountOfContract"
                dense
                outlined
              ></v-text-field>
            </div>
          </div>

          <div class="d-flex justify-content-start border-top pt-10">
            <div class="card-toolbar">
              <a @click="save" class="btn btn-primary font-weight-bolder">
                <span class="svg-icon svg-icon-white"
                  ><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2020-09-15-014444/theme/html/demo1/dist/../src/media/svg/icons/Communication/Send.svg--><svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M3,13.5 L19,12 L3,10.5 L3,3.7732928 C3,3.70255344 3.01501031,3.63261921 3.04403925,3.56811047 C3.15735832,3.3162903 3.45336217,3.20401298 3.70518234,3.31733205 L21.9867539,11.5440392 C22.098181,11.5941815 22.1873901,11.6833905 22.2375323,11.7948177 C22.3508514,12.0466378 22.2385741,12.3426417 21.9867539,12.4559608 L3.70518234,20.6826679 C3.64067359,20.7116969 3.57073936,20.7267072 3.5,20.7267072 C3.22385763,20.7267072 3,20.5028496 3,20.2267072 L3,13.5 Z"
                        fill="#000000"
                      />
                    </g></svg
                  ><!--end::Svg Icon--></span
                >

                Yuborish</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  data() {
    return {
      menu2: false,
      menu3: false,
      items: [
        { state: 'Muddatli', abbr: 'MU' },
        { state: 'Muddati cheklanmagan', abbr: 'MS' },
        { state: "Summaga bog'liq", abbr: 'SU' }
      ],
      money: {
        decimal: ',',
        thousands: ' ',
        precision: 0,
        masked: false
      },
      typeOfContract: '',
      dateOFContract: '',
      contractNumber: '',
      endOfContract: '',
      amountOfContract: ''
    }
  },
  beforeCreate() {
    this.$store.dispatch('getClientContract', this.$route.params.id)
  },
  computed: {
    getClientContract() {
      // return this.$store.state.requests.ContractById;
      return this.$store.state.requests.allContracts
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Shartnoma registratsiyasi' }
    ])
  },
  methods: {
    changed() {
      this.dateOFContract = null
      this.contractNumber = null
      this.endOfContract = null
      this.amountOfContract = null
    },
    save() {
      // this.$store.state.requests.allClientsName.forEach(element => {
      //   if (element.full_name == this.combobox) {
      //     this.combobox = element.id;
      //   }
      // });
      if (this.amountOfContract !== null) {
        this.amountOfContract = this.amountOfContract.replace(/\s/g, '')
      }
      const data = {
        client_name: this.getClientContract.id,
        contract_type: this.typeOfContract,
        end_date: this.endOfContract,
        start_date: this.dateOFContract,
        contract_number: '',
        amount: this.amountOfContract,
        parent_contract: this.$route.params.id,
        client: this.getClientContract.client
      }
      this.$store.dispatch('createContract', data)
      this.dateOFContract = null
      this.contractNumber = null
      this.endOfContract = null
      this.amountOfContract = null
    }
  }
}
</script>
